<template>
  <div>
    <v-row>
      <v-col>
        <v-card>
          <v-card-text>
            <date-time-picker
              @getDateFrom="dateFrom = $event"
              @getDateTo="dateTo = $event"
            >
            </date-time-picker>
            <v-autocomplete
              v-model="selectedStatuses"
              :items="reportStatuses[$i18n.locale]"
              item-text="name"
              item-value="key"
              :label="$t('Status')"
              multiple
              clearable
              hide-details
              :menu-props="{contentClass:'list-style'}"
            >
              <template #selection="data">
                <v-chip
                  v-bind="data.attrs"
                  label
                  :input-value="data.selected"
                  close
                  @click:close="selectedStatuses = handleRemoveFromArray(selectedStatuses, data.item.key)"
                >
                  {{ data.item.name }}
                </v-chip>
              </template>
            </v-autocomplete>
            <v-btn
              class="mt-5"
              color="primary"
              @click="getData"
            >
              {{ $t('BtnSearch') }}
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="dataFromDb"
          item-key="id"
          class="elevation-1"
          :search="search"
          :sort-by.sync="orderBy"
          :sort-desc.sync="sortDesc"
          :loading="loading"
          :locale="$i18n.locale === 'ru' ? 'ru-RU' : 'en-US'"
        >
          <template
            v-slot:top
          >
            <div class="row">
              <div class="col-8">
                <div class="container">
                  <v-btn
                    color="primary"
                    @click="createDialog = true"
                  >
                    {{ $t('ReportGeneration.CreateQuery') }}
                  </v-btn>
                </div>
              </div>
              <div class="col-4">
                <v-text-field
                  v-model="search"
                  :label="$t('LblSearch')"
                  class="mx-4"
                ></v-text-field>
              </div>
            </div>
          </template>
          <template #[`item.createdDate`]="{item}">
            <v-row>
              <v-col>
                {{ moment(item.createdDate).format("YYYY-MM-DD h:mm:ss") }}
              </v-col>
            </v-row>
          </template>
          <template #[`item.updatedDate`]="{item}">
            <v-row>
              <v-col>
                {{ item.updatedDate ? moment(item.updatedDate ).format("YYYY-MM-DD h:mm:ss") : '' }}
              </v-col>
            </v-row>
          </template>
          <template #[`item.status`]="{item}">
            <v-row>
              <v-col>
                <v-chip
                  v-show="item.status === 'NEW'"
                  color="info"
                >
                  {{ $t('ReportGeneration.Statuses.NEW') }}
                </v-chip>
                <v-chip
                  v-show="item.status === 'IN_PROGRESS'"
                  color="warning"
                >
                  {{ $t('ReportGeneration.Statuses.IN_PROGRESS') }}
                </v-chip>
                <v-chip
                  v-show="item.status === 'COMPLETED'"
                  color="success"
                >
                  {{ $t('ReportGeneration.Statuses.COMPLETED') }}
                </v-chip>
                <v-chip
                  v-show="item.status === 'FAILED'"
                  color="error"
                >
                  {{ $t('ReportGeneration.Statuses.FAILED') }}
                </v-chip>
              </v-col>
            </v-row>
          </template>
          <template #[`item.actions`]="{item}">
            <v-row>
              <v-col>
                <v-menu
                  top
                  offset-x
                  offset-y
                >
                  <template
                    v-slot:activator="{ on, attrs }"
                  >
                    <v-btn
                      v-if="$checkAbility('USER_UPDATE') && $checkAbility('USER_DELETE') && item.id !== $getUserId()"
                      color="primary"
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <template>
                      <v-list-item
                        @click="openInfoDialog(item)"
                      >
                        <v-list-item-icon class="mr-3">
                          <v-icon v-text="icons.mdiInformation"></v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>{{ $t('ReportGeneration.Details') }}</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        :disabled="item.status === 'IN_PROGRESS'"
                        @click="openWarningDialog(item.id)"
                      >
                        <v-list-item-icon class="mr-3">
                          <v-icon v-text="icons.mdiTrashCanOutline"></v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>{{ $t('ReportGeneration.Delete') }}</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        :disabled="item.status !== 'COMPLETED'"
                        @click="downloadReport(item.id,item.name)"
                      >
                        <v-list-item-icon class="mr-3">
                          <v-icon v-text="icons.mdiDownload"></v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>{{ $t('ReportGeneration.Download') }}</v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog
      v-model="createDialog"
      max-width="70%"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ $t('ReportGeneration.CreateReport') }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <date-time-picker
              @getDateFrom="dateFromCreate = $event"
              @getDateTo="dateToCreate = $event"
            >
            </date-time-picker>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-select
                  v-model="advertiserId"
                  :items="advertisers"
                  item-text="name"
                  item-value="id"
                  :label="$t('Advertiser')"
                  :disabled="loadingFirst"
                ></v-select>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-select
                  v-model="campaignIds"
                  :items="campaigns"
                  item-text="name"
                  item-value="id"
                  :label="$t('CampaignFilter')"
                  multiple
                  clearable
                  :disabled="loadingFirst"
                  hide-details
                  :menu-props="{contentClass:'list-style'}"
                >
                  <template #selection="data">
                    <v-chip
                      v-bind="data.attrs"
                      label
                      :input-value="data.selected"
                      close
                      @click:close="campaignIds = handleRemoveFromArray(campaignIds, data.item.id)"
                    >
                      {{ data.item.name }}
                    </v-chip>
                  </template>
                  <template v-slot:prepend-item>
                    <v-list-item
                      ripple
                      @mousedown.prevent
                      @click="toggle"
                    >
                      <v-list-item-action>
                        <v-icon :color="campaignIds.length > 0 ? 'primary' : ''">
                          {{ icon }}
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ $t('ReportGeneration.SelectAll') }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="createReport()"
          >
            {{ $t('ReportGeneration.Save') }}
          </v-btn>
          <v-btn
            color="primary"
            outlined
            @click="cancelCreating()"
          >
            {{ $t('ReportGeneration.Close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="infoDialog"
      max-width="70%"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ $t('ReportGeneration.DetailedInfo') }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="2"
                sm="2"
                md="2"
                class="mt-6"
              >
                <div class="text-center d-flex justify-strat">
                  ID
                </div>
              </v-col>
              <v-col
                cols="10"
                sm="10"
                md="10"
              >
                <v-text-field
                  v-model="itemForInfo.id"
                  label="ID"
                  single-line
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="2"
                sm="2"
                md="2"
                class="mt-6"
              >
                <div class="text-center d-flex justify-strat">
                  {{ $t('ReportGeneration.Name') }}
                </div>
              </v-col>
              <v-col
                cols="10"
                sm="10"
                md="10"
              >
                <v-text-field
                  v-model="itemForInfo.name"
                  :label="$t('ReportGeneration.Name')"
                  single-line
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="2"
                sm="2"
                md="2"
                class="mt-1"
              >
                <div class="text-center d-flex justify-strat">
                  {{ $t('ReportGeneration.Parameters') }}
                </div>
              </v-col>
              <v-col
                cols="10"
                sm="10"
                md="10"
              >
                <prism language="javascript">
                  {{ parameters }}
                </prism>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="2"
                sm="2"
                md="2"
                class="mt-1"
              >
                <div class="text-center d-flex justify-strat">
                  {{ $t('ReportGeneration.Status') }}
                </div>
              </v-col>
              <v-col
                cols="10"
                sm="10"
                md="10"
              >
                <v-chip
                  v-show="itemForInfo.status === 'NEW'"
                  color="info"
                >
                  {{ $t('ReportGeneration.Statuses.NEW') }}
                </v-chip>
                <v-chip
                  v-show="itemForInfo.status === 'IN_PROGRESS'"
                  color="warning"
                >
                  {{ $t('ReportGeneration.Statuses.IN_PROGRESS') }}
                </v-chip>
                <v-chip
                  v-show="itemForInfo.status === 'COMPLETED'"
                  color="success"
                >
                  {{ $t('ReportGeneration.Statuses.COMPLETED') }}
                </v-chip>
                <v-chip
                  v-show="itemForInfo.status === 'FAILED'"
                  color="error"
                >
                  {{ $t('ReportGeneration.Statuses.FAILED') }}
                </v-chip>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="2"
                sm="2"
                md="2"
                class="mt-6"
              >
                <div class="text-center d-flex justify-strat">
                  {{ $t('ReportGeneration.User') }}
                </div>
              </v-col>
              <v-col
                cols="10"
                sm="10"
                md="10"
              >
                <v-text-field
                  v-model="itemForInfo.userId.name"
                  :label="$t('ReportGeneration.User')"
                  single-line
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="2"
                sm="2"
                md="2"
                class="mt-6"
              >
                <div class="text-center d-flex justify-strat">
                  {{ $t('ReportGeneration.CreatedDate') }}
                </div>
              </v-col>
              <v-col
                cols="10"
                sm="10"
                md="10"
              >
                <v-text-field
                  :label="$t('ReportGeneration.CreatedDate')"
                  :value="`${itemForInfo.createdDate ? itemForInfo.createdDate.substr(0, 10) : ''} ${itemForInfo.createdDate ? itemForInfo.createdDate.substr(11, 8) : ''}`"
                  single-line
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="2"
                sm="2"
                md="2"
                class="mt-6"
              >
                <div class="text-center d-flex justify-strat">
                  {{ $t('ReportGeneration.UpdatedDate') }}
                </div>
              </v-col>
              <v-col
                cols="10"
                sm="10"
                md="10"
              >
                <v-text-field
                  :label="$t('ReportGeneration.UpdatedDate')"
                  :value="`${itemForInfo.updatedDate ? itemForInfo.updatedDate.substr(0, 10) : ''} ${itemForInfo.updatedDate ? itemForInfo.updatedDate.substr(11, 8) : ''}`"
                  single-line
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <!-- <v-btn
            color="primary"
            :disabled="itemForInfo.status !== 'COMPLETED'"
            @click="downloadReport(itemForInfo.id)"
          >
            <v-icon>{{ icons.mdiDownload }}</v-icon>
            {{ $t('ReportGeneration.Download') }}
          </v-btn> -->
          <!-- <v-btn
            color="warning"
            :disabled="itemForInfo.status === 'IN_PROGRESS'"
            @click="openWarningDialog(itemForInfo.id)"
          >
            <v-icon>{{ icons.mdiTrashCanOutline }}</v-icon>
            {{ $t('ReportGeneration.Delete') }}
          </v-btn> -->
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            outlined
            @click="infoDialog = false"
          >
            {{ $t('ReportGeneration.Close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="deleteDialog"
      persistent
      max-width="290"
    >
      <v-card>
        <v-card-title class="text-h5">
          {{ $t('ReportGeneration.Warning') }}
        </v-card-title>
        <v-card-text>{{ $t('ReportGeneration.WarningText') }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="deleteDialog = false"
          >
            {{ $t('UserPage.No') }}
          </v-btn>
          <v-btn
            color="success"
            text
            @click="deleteReport()"
          >
            {{ $t('UserPage.Yes') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiCheckboxBlankOutline,
  mdiCloseBox,
  mdiDotsVertical,
  mdiDownload,
  mdiInformation,
  mdiMinusBox,
  mdiTrashCanOutline,
} from '@mdi/js'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import qs from 'qs'
import Prism from 'vue-prism-component'
import DateTimePicker from '../Components/DateTimePicker.vue'

export default {
  components: {
    DateTimePicker,
    Prism,
  },
  data() {
    return {
      orderBy: 'createdDate',
      sortDesc: true,
      search: '',
      excelStatistics: [],
      advertiserId: 0,
      advertisers: [],
      campaignIds: [],
      campaigns: [],
      dataFromDb: [],
      headers: [],
      selectedCampaigns: [],
      createDialog: false,
      infoDialog: false,
      deleteDialog: false,
      reportToDeleteId: 0,
      reportId: Number(this.$route.params.report_id),
      dateFrom: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        1,
        new Date().getHours(),
        new Date().getMinutes(),
        new Date().getSeconds(),
      )
        .toISOString()
        .substr(0, 10),
      dateTo: new Date().toISOString().substr(0, 10),
      dateFromCreate: new Date().toISOString().substr(0, 10),
      dateToCreate: new Date().toISOString().substr(0, 10),
      loading: false,
      loadingFirst: false,
      itemForInfo: { userId: {} },
      parameters: '',
      icons: {
        mdiDotsVertical,
        mdiDownload,
        mdiInformation,
        mdiTrashCanOutline,
        mdiCloseBox,
        mdiMinusBox,
        mdiCheckboxBlankOutline,
      },
      selectedStatuses: ['NEW', 'IN_PROGRESS', 'COMPLETED', 'FAILED'],
      reportStatuses: {
        en: [
          {
            name: 'New',
            key: 'NEW',
          },
          {
            name: 'In progress',
            key: 'IN_PROGRESS',
          },
          {
            name: 'Completed',
            key: 'COMPLETED',
          },
          {
            name: 'Failed',
            key: 'FAILED',
          },
        ],
        ru: [
          {
            name: 'Новый',
            key: 'NEW',
          },
          {
            name: 'В обработке',
            key: 'IN_PROGRESS',
          },
          {
            name: 'Завершён',
            key: 'COMPLETED',
          },
          {
            name: 'Отклонён',
            key: 'FAILED',
          },
        ],
        tr: [
          {
            name: 'Yeni',
            key: 'NEW',
          },
          {
            name: 'İşlemde',
            key: 'IN_PROGRESS',
          },
          {
            name: 'Tamamlanmış',
            key: 'COMPLETED',
          },
          {
            name: 'Reddedilmiş',
            key: 'FAILED',
          },
        ],
      },
    }
  },

  computed: {
    selectedAll() {
      return this.campaignIds.length === this.campaigns.length
    },
    selectedSome() {
      return this.campaignIds.length > 0 && !this.selectedAll
    },
    icon() {
      if (this.selectedAll) return this.icons.mdiCloseBox
      if (this.selectedSome) return this.icons.mdiMinusBox

      return this.icons.mdiCheckboxBlankOutline
    },
  },

  watch: {
    advertiserId() {
      this.campaignIds = []
      this.getCampaings()
    },
    $route() {
      this.reportId = Number(this.$route.params.report_id)
      this.getReportFromNotification()
    },
  },

  mounted() {
    this.firstLoad()

    // this.$root.$on('update_city', this.getData())
  },

  methods: {
    firstLoad() {
      this.getAdvertisersOrProviders().then(() => {
        this.getCampaings().then(() => {
          if (this.reportId) {
            this.getReportFromNotification()
          } else {
            this.getData()
          }
        })
      })
    },
    toggle() {
      this.$nextTick(() => {
        if (this.selectedAll) {
          this.campaignIds = []
        } else {
          this.campaignIds = []
          this.campaigns.forEach(item => {
            this.campaignIds.push(item.id)
          })
        }
      })
    },
    getData() {
      this.loading = true
      const dateStart = this.dateFrom != null ? `${this.dateFrom}` : '1970-01-01'
      // eslint-disable-next-line operator-linebreak
      const dateFinish = this.dateTo != null ? `${this.dateTo}` : `${new Date().toISOString().substr(0, 10)}`

      const params = {
        params: {
          dateFrom: dateStart,
          dateTo: dateFinish,
          statuses: this.selectedStatuses,
          appName: 'md-dashboard-app',
        },
        paramsSerializer: param => qs.stringify(param, { arrayFormat: 'repeat' }),
      }

      this.$http.get(`${this.$apiBaseURL}/report/generation`, params).then(response => {
        this.dataFromDb = response.data !== null ? response.data : []

        // console.log(this.dataFromDb)
        this.headers = []

        this.headers.push({ text: this.$t('ReportGeneration.Name'), value: 'name' })
        this.headers.push({ text: this.$t('ReportGeneration.CreatedDate'), value: 'createdDate' })
        this.headers.push({ text: this.$t('ReportGeneration.UpdatedDate'), value: 'updatedDate' })
        this.headers.push({ text: this.$t('ReportGeneration.Status'), value: 'status' })
        this.headers.push({ text: this.$t('ReportGeneration.User'), value: 'userId.name' })
        this.headers.push({ text: this.$t('ReportGeneration.Actions'), value: 'actions' })

        this.loading = false
      })
    },
    handleRemoveFromArray(array, element) {
      return array.filter(ele => ele !== element)
    },
    openInfoDialog(item) {
      this.itemForInfo = {}
      this.itemForInfo = item
      // eslint-disable-next-line prefer-const
      this.parameters = `${this.$t('ReportGeneration.Advertiser')}: ${item.parameters.advertiserId}
  ${this.$t('ReportGeneration.Campaigns')}: [${item.parameters.campaignIds}]
  ${this.$t('DateFrom')}: ${new Date(item.parameters.dateFrom).toISOString().substr(0, 10)}
  ${this.$t('DateTo')}: ${new Date(item.parameters.dateTo).toISOString().substr(0, 10)}`
      this.infoDialog = true
    },
    deleteReport() {
      const params = {
        params: { id: this.reportToDeleteId },
      }
      this.$http.delete(`${this.$apiBaseURL}/report/generation/delete`, params).then(() => {
        this.getData()
        this.deleteDialog = false
      })
    },
    downloadReport(id, reportName) {
      // const filename = `${reportName}.xls`
      // const link = document.createElement('a')
      // link.href = `${this.$apiBaseURL}/report/generation/download?id=${id}`
      // link.download = filename
      // link.click()

      // Замените 'ваш_url' на URL, по которому можно получить Excel-файл
      const excelFileUrl = `${this.$apiBaseURL}/report/generation/download?id=${id}&lang=${this.$i18n.locale}`

      // Отправка GET-запроса для получения Excel-файла
      this.$http
        .get(excelFileUrl, {
          responseType: 'blob', // Указание на то, что ожидается файловый тип ответа
        })
        .then(response => {
          // Создание Blob (Binary Large Object) из полученных данных
          const blob = new Blob([response.data], { type: response.headers['content-type'] })

          // Создание URL для Blob
          const excelFile = window.URL.createObjectURL(blob)

          // Создание ссылки для скачивания файла
          const link = document.createElement('a')
          link.href = excelFile

          // Установка атрибута download для указания имени файла
          // console.log(reportName)
          link.download = `${reportName}.xlsx`

          // Симуляция клика по ссылке для скачивания файла
          link.click()

          // Очистка созданных ресурсов
          window.URL.revokeObjectURL(excelFileUrl)
        })
        .catch(error => {
          // Обработка ошибок
          console.error('Ошибка запроса:', error)
        })

      // const params = {
      //   params: { id },
      // }
      // this.$http.get(`${this.$apiBaseURL}/report/generation/download`, params).then(response => {
      //   const filename = `${reportName}.xls`
      //   const file = new File([response.data], filename, {
      //     type: 'application/xls',
      //     lastModified: new Date(),
      //   })

      //   // const blob = new Blob([response.data], { type: 'application/xlsx' })
      //   // const link = document.createElement('a')
      //   // link.href = window.URL.createObjectURL(blob)
      //   // link.download = filename
      //   // link.click()
      // })
    },
    createReport() {
      const dateStart = this.dateFromCreate != null ? Math.floor(new Date(this.dateFromCreate).getTime()) : 0
      // eslint-disable-next-line operator-linebreak
      const dateFinish =
        this.dateToCreate != null ? Math.floor(new Date(this.dateToCreate).getTime()) : Math.floor(new Date().getTime())

      console.log(`${dateStart} ${dateFinish}`)

      const params = {
        userId: this.$getUser(),
        appName: 'md-dashboard-app',
        status: 'NEW',
        parameters: {
          dateFrom: dateStart,
          dateTo: dateFinish,
          campaignIds: this.campaignIds,
          advertiserId: this.advertiserId,
        },
      }

      this.$http.post(`${this.$apiBaseURL}/report/generation`, params).then(() => {
        this.campaignIds = []
        this.getAdvertisersOrProviders()

        this.createDialog = false

        this.getData()
      })
    },

    cancelCreating() {
      this.campaignIds = []
      this.getAdvertisersOrProviders()

      this.createDialog = false
    },

    getAdvertisersOrProviders() {
      this.loadingFirst = true

      if (!this.$checkRole('PROVIDERS')) {
        return this.$http
          .get(`${this.$apiBaseURL}/publisher/filter/adv-users`, { params: { userId: this.$getUserId() } })
          .then(response => {
            this.advertisers = response.data !== null ? response.data : []
            if (this.advertisers.length > 0) {
              this.advertiserId = this.advertisers[0].id
            }
            if (!this.advertiserId) {
              if (this.advertisers.length > 0) {
                this.advertiserId = this.advertisers[0].id
              }
            }

            this.loadingFirst = false
          })
      }

      return this.$http
        .get(`${this.$apiBaseURL}/campaigns/filter/providers`, { params: { userId: this.$getUserId() } })
        .then(response => {
          this.providers = response.data

          this.loadingFirst = false
        })
    },
    getCampaings() {
      this.loadingCampaign = true
      if (!this.$checkRole('PROVIDERS')) {
        return this.$http
          .get(`${this.$apiBaseURL}/campaigns/filter/campaigns`, {
            params: { userId: this.$getUserId(), advertiserId: this.advertiserId },
          })
          .then(response => {
            this.campaigns = response.data !== null ? response.data : []

            this.loadingCampaign = false
          })
          .catch(error => {
            // TODO: Next Update - Show notification
            this.showMessage = true
            this.messageText = error
          })
      }

      return this.$http
        .get(`${this.$apiBaseURL}/campaigns/filter/campaigns`, {
          params: { userId: this.$getUserId(), providerIds: this.providerId },
        })
        .then(response => {
          this.campaigns = response.data !== null ? response.data : []

          this.loadingCampaign = false
        })
        .catch(error => {
          // TODO: Next Update - Show notification
          this.showMessage = true
          this.messageText = error
        })
    },
    openWarningDialog(id) {
      this.reportToDeleteId = id
      this.deleteDialog = true
    },
    getReportFromNotification() {
      this.loading = true
      const dateStart = '1970-01-01'
      // eslint-disable-next-line operator-linebreak
      const dateFinish = `${new Date().toISOString().substr(0, 10)}`

      const params = {
        params: {
          dateFrom: dateStart,
          dateTo: dateFinish,
          statuses: this.selectedStatuses,
          appName: 'md-dashboard-app',
        },
        paramsSerializer: param => qs.stringify(param, { arrayFormat: 'repeat' }),
      }

      this.$http.get(`${this.$apiBaseURL}/report/generation`, params).then(response => {
        const data = response.data !== null ? response.data : []

        const result = data.filter(c => c.id === this.reportId)

        this.dataFromDb = result || []

        // console.log(this.dataFromDb)
        this.headers = []

        this.headers.push({ text: this.$t('ReportGeneration.Name'), value: 'name' })
        this.headers.push({ text: this.$t('ReportGeneration.CreatedDate'), value: 'createdDate' })
        this.headers.push({ text: this.$t('ReportGeneration.UpdatedDate'), value: 'updatedDate' })
        this.headers.push({ text: this.$t('ReportGeneration.Status'), value: 'status' })
        this.headers.push({ text: this.$t('ReportGeneration.User'), value: 'userId.name' })
        this.headers.push({ text: this.$t('ReportGeneration.Actions'), value: 'actions' })

        this.loading = false
      })
    },
  },
}
</script>

<style lang="scss">
pre[class*='language-'] {
  border-radius: $card-border-radius;
  max-height: 350px;
}
</style>
